<template lang="pug">
  .wc-loaders-cube.wc-loader-spinner(v-bind:style='spinnerStyle')
    .wc-loader-shadow
    .wc-loader-animate(v-bind:style='animateStyle')
</template>

<script>
export default {
  name: 'wc-loaders-cube',
  props: {
    color: {
      type: String,
      default: '#AA1675',
    },
    size: {
      type: String,
      default: '50px',
    },
  },
  computed: {
    spinnerStyle() {
      return {
        width: this.size,
        height: this.size,
      }
    },
    animateStyle() {
      return {
        backgroundColor: this.color,
      }
    },
  },
}
</script>

<style lang="scss">
.wc-loader-spinner {
  position: relative;
  * {
    line-height: 0;
    box-sizing: border-box;
  }
  .wc-loader-shadow {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    min-width: 5px;
    background-color: #000;
    opacity: 0.1;
    border-radius: 50%;
    animation: wc-loaders-cube-animate-shadow 0.5s linear infinite;
  }
  .wc-loader-animate {
    position: absolute;
    bottom: 40%;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    animation: wc-loaders-cube-animate 0.5s linear infinite;
  }
}
@keyframes wc-loaders-cube-animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(20%) rotate(22.5deg);
  }
  50% {
    border-bottom-right-radius: 50%;
    transform: translateY(40%) scale(1, 0.9) rotate(45deg);
  }
  75% {
    transform: translateY(20%) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}
@keyframes wc-loaders-cube-animate-shadow {
  0%,
  100% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1);
  }
}
</style>
